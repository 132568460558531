/**
 * 热点问题js
 */
import axios from "@/resources/js/request";
import da from "element-ui/src/locale/lang/da";

const request = axios.request;
/**
 * 获取热门问题信息
 * @returns {Promise<unknown>}
 */
export const gethotissues = (data) => {
  return request.doPost('/hotissues/gethotissues', data);
}

/**
 * 添加或者修改热门问题信息
 * @returns {Promise<unknown>}
 */
export const addorupdatehotissues = (data) => {
  return request.doPost('/hotissues/addOrUpdatehotissues', data);
}

/**
 * 删除热门问题信息
 * @returns {Promise<unknown>}
 */
export const deletehotissues = (data) => {
  return request.doPost('/hotissues/deletehotissues', data);
}

/**
 * 修改热门问题状态
 * @returns {Promise<unknown>}
 */
export const updatehotissuesStatus = (data) => {
  return request.doPost('/hotissues/updateHotIssuesStatus', data);
}
/**
 * 获取热门问题最大排序
 * @returns {Promise<unknown>}
 */
export const querymaxsortid = (data) => {
  return request.doPost('/hotissues/querymaxsortid', data);
}
